<template>
  <a-dialog-form :value="value" @input="close()">
    <v-card>
      <v-card-title>{{
        view == 1 ? "Статус" : "Введите задание"
      }}</v-card-title>
      <v-card-text>
        <a-loader v-if="loading" />
        <template v-if="view == 1">
          <!-- <a-input-select
            v-model="data.caller_events.status"
            :model="model.caller_events.status"
          /> -->
          <a-input-text
            v-model="data.caller_request.comments"
            :model="model.caller_request.comments"
          />
        </template>
        <template v-if="view == 2">
          <a-input-datetime
            v-model="data.caller_events.eventon"
            :model="model.caller_events.eventon"
          />
          <!-- <a-input-select
            v-model="data.caller_events.status"
            :model="model.caller_events.status"
          /> -->
          <a-input-text
            v-model="data.caller_events.comments"
            :model="model.caller_events.comments"
          />
        </template>
      </v-card-text>
      <v-card-actions>
        <a-btn-form-close @click="close()" />
        <template v-if="status == 5 && view == 1">
          <a-btn-form-save @click="view = 2">Далее</a-btn-form-save>
        </template>
        <template v-else>
          <a-btn-form-save @click="submit(2)">Записать</a-btn-form-save>
          <a-btn-form-save @click="submit(1)"
            >Записать и получить новую карточку</a-btn-form-save
          >
        </template>
      </v-card-actions>
    </v-card>
  </a-dialog-form>
</template>

<script>
export default {
  props: {
    value: Boolean,
    status: Number,
    item_id: Number,
  },
  data() {
    return {
      loading: false,
      view: 1,
      data: {},
      model: {
        caller_events: {
          eventon: {
            title: "Дата",
          },
          // info: {
          //   title: "Выберите задание",
          //   options: this.$root.config.meta.enum.eventsInfo,
          // },
          comments: {
            title: "Комментарий",
          },
        },
        caller_request: {
          comments: {
            title: "Комментарий",
          },
        },
      },
    };
  },
  created() {
      this.init();
  },
  watch: {
    value() {
      this.init();
    },
  },
  computed: {
  },
  methods: {
    init() {
      this.loading = false;
      this.view = 1;
      this.$set(this, "data", {
        caller_events: {
          caller_item_id: this.item_id,
          status: 0,
          eventon: new Date().addDays(1).date + " 10:00:00",
          info: null,
          comments: null,
        },
        caller_request: {
          caller_item_id: this.item_id,
          status: this.status,
          info: null,
          comments: null,
        },
      });
    },
    close() {
      this.view = 1;
      this.$emit("input");
    },
    async submit(type) {
      await this.onSubmit(this.$root.api.cardRequest, this.data.caller_request);
      if (this.view == 2) {
        await this.onSubmit(this.$root.api.cardEvent, this.data.caller_events);
      }
      if (type == 1) {
        return this.$router.push({ name: "get-card-item" });
      } else if (type == 2) {
        return this.$router.push({ name: "index" });
      }
    },
    async onSubmit(api, data) {
      this.loading = true;
      let response = await this.$axios.p(api, data);
      this.loading = false;
      return response.data.status == "ok";
    },
  },
};
</script>